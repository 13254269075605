@import 'styles/_sass-includes.scss';

.product-tile-grid {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.product-tile-grid-actions {
  width: 100%;
  display: flex;
  justify-content: center;
}

.product-tile {
  display: inline-block;
  position: relative;
  width: calc(25% - 2rem);
  box-sizing: border-box;
  margin: 0 1rem 6rem;
  // media queries
  @include breakpoint($tablet-lg) {
    width: calc(33.33% - 2rem);
  }
  @include breakpoint($tablet-sm) {
    width: calc(50% - 2rem);
  }
  @include breakpoint($mobile-md) {
    width: 100%;
    margin: 0 0 3rem;
  }

  &.square {
    width: calc(25% - 1.5rem);
    margin: 0 0.75rem 3rem;
    // media queries
    @include breakpoint($tablet-lg) {
      width: calc(33.33% - 1.5rem);
    }
    @include breakpoint($tablet-sm) {
      width: calc(50% - 1.5rem);
    }
  }
}
.square {
  .product-tile-image {
    background-color: #f6f6f9;
  }
}
.product-tile-image {
  width: 100%;
  padding: 0 1.2rem 143%;
  background-color: $lightgrey;
  margin-bottom: 16px;
  position: relative;
  // media queries
  @include breakpoint($mobile-sm) {
    margin-bottom: 1.5rem;
  }

  > div {
    border-radius: 1em;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #D9D9D9;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  .product-tile-additional-image:not(.active) {
    opacity: 0;
  }

  .product-tile-quick-add-to-cart-btn {
    height: 32px;
    width: 32px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    z-index: 2;
    position: absolute;
    right: 0;
    margin: 4% 4% 0 0;
    min-height: 35px;
    min-width: 35px;

    span {
      display: inline-flex;
    };

    @include breakpoint($mobile-lg) {
      height: 40px;
      width: 40px;
      border-radius: 12px;
      margin: 12px 12px 0 0;
    }

    @include breakpoint($mobile-sm) {
      height: 40px;
      width: 40px;
      border-radius: 12px;
      margin: 8px 8px 0 0;
    }

    &.loading {
      background-color: $blue !important;
      color: $white !important;

      .pr_loader {
        opacity: 100;
      }

      .pr_icon  {
        @include breakpoint($mobile-lg) {
          width: 26px;
          height: 26px;
        }
      }
    }

    .product-tile-cart-item-count {
      position: absolute;
      top: -7px;
      right: -7px;
      font-size: 12px;
      font-weight: bold;
      color: var(--primary-ui);
      background-color: $white;
      border-radius: 50%;
      padding: 1px 5px;
      border: 1.5px solid var(--primary-ui);

      @include breakpoint($mobile-sm) {
        font-size: 10px;
      }
    }
  }

  .square & {
    height: 0;
    padding-bottom: 100%;
  }
}

.product-tile-details {
  width: 100%;
  color: $black;
  text-align: center;
  font-size: 1.6rem;
  display: flex;
  padding: 0 .4em;
  justify-content: space-between;
  // media queries
  @include breakpoint($mobile-md) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.product-tile-info {
  margin-right: 2em;
  text-align: left;
  // media queries
  @include breakpoint($mobile-md) {
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.product-tile-title {
  font-weight: 400;
  display: block;
  color: var(--neutral-70);
  font-size: 14px;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
}

.product-tile-category {
  font-size: 14px;
  text-align: left;
  color: var(--neutral-30);
}

.product-tile-product {
  font-size: 1.4rem;
  font-weight: 100;
  padding: 1rem 0 2rem;
  margin-bottom: 0;
  display: block;
  line-height: 1;
}

.product-tile-price {
  font-weight: bold;
}

.shortened-text{
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}